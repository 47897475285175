import React from 'react';
import Layout from '../components/Layout/layout';
import Register from '../Views/Portal/Register/Register';

const RegisterPage: React.FC = () => {
  return (
    <Layout>
      <Register />
    </Layout>
  );
};

export default RegisterPage;
