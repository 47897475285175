import { navigate } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../components/Banner/Banner';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import RegisterForm from '../../../components/Register/Register';
import { UserContext } from '../../../Context/UserContext';
import * as styles from './Register.module.scss';

const Register: React.FC = () => {
  const { User } = useContext(UserContext);

  useEffect(() => {
    if (User.ID)
      navigate("/Portal/");
  }, [User])

  return (<>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>PrimeHR :: Register</title><meta charSet='utf-8' />
    </Helmet>

    <Banner />

    <Breadcrumb Trail={[
      { To: "", Text: "Register" }
    ]} />

    <div className={styles.Login}>
      <RegisterForm />
    </div>
  </>
  );
};

export default Register;
